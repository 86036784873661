/**
 * Seo component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

 import React from "react"
 import PropTypes from "prop-types"
 import { Helmet } from "react-helmet"
 import { useStaticQuery, graphql } from "gatsby"
 
 const Seo = ({ title, description, lang, meta, image:metaImage, canonical, schema }) => {
   const { wp, site } = useStaticQuery(
     graphql`
       query {
         wp {
           generalSettings {
             title
             description
           }
         }
 
         site {   
          siteMetadata {
            author
            siteUrl
          }
        }
       }
     `
   )
 
   const metaDescription = description || wp.generalSettings?.description
   const defaultTitle = wp.generalSettings?.title
   const image =
    metaImage && metaImage.src
     ? `${site.siteMetadata.siteUrl}${metaImage.src}`
     : null

  const fullSchema = schema || null

   return (
     <Helmet
       htmlAttributes={{
         lang,
       }}
       title={title}
       defaultTitle={defaultTitle}
       titleTemplate={title ? `%s` : null}
       link={
        canonical
          ? [{ rel: 'canonical', key: canonical, href: canonical }]
          : []
      }
       meta={[
         {
           name: `description`,
           content: metaDescription,
         },
         {
           property: `og:title`,
           content: title,
         },
         {
           property: `og:description`,
           content: metaDescription,
         },
         {
           property: `og:type`,
           content: `website`,
         },
         {
           name: `twitter:card`,
           content: `summary`,
         },
         {
           name: `twitter:creator`,
           content: site.siteMetadata?.author || ``,
         },
         {
           name: `twitter:title`,
           content: title,
         },
         {
           name: `twitter:description`,
           content: metaDescription,
         },
       ].concat(
        metaImage
          ? [
              {
                property: "og:image",
                content: image,
              },
              {
                property: "og:image:width",
                content: metaImage.width,
              },
              {
                property: "og:image:height",
                content: metaImage.height,
              },
              {
                name: "twitter:card",
                content: "summary_large_image",
              },
              {
                name: "twitter:image",
                content: image,
              }
            ]
          : [
              {
                name: "twitter:card",
                content: "summary",
              },
            ]
      ).concat(meta)}
      encodeSpecialCharacters={false}
     >
        {fullSchema && <script type="application/ld+json">{JSON.stringify(fullSchema)}</script>}
     </Helmet>
   )
 }
 
 Seo.defaultProps = {
   lang: `en`,
   meta: [],
   description: ``,
 }
 
 Seo.propTypes = {
   canonical: PropTypes.string,
   description: PropTypes.string,
   lang: PropTypes.string,
   meta: PropTypes.arrayOf(PropTypes.object),
   title: PropTypes.string.isRequired,
 }
 
 export default Seo
 