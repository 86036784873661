import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components-tr/seo"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const description = "İşletmenizin dijitalleşme sürecinde kullanabileceğiniz dijital ürün kaynakları."

const Resources = () => (
  <Layout navbarTheme="dark" className="bg-cream">
    <Seo title="Resources" description={description} canonical="https://tio.ist/tr/resources/" />
 
    <div className="bg-white relative py-10">
        <div className="container text-2xl text-center mx-auto  lg:px-10 max-w-2xl">
            <h1 className="text-5xl p-10 font-bold text-blue">
            Kaynaklar
            </h1>
            <p className="font-light">
            Bu kaynakları istediğiniz gibi kullanabilir, istediğiniz kişilerle paylaşabilirsiniz. 
            </p>           
        </div>
    </div>
    <div className="relative font-thin ">
        <div className="">
            <div className="mx-auto text-center absolute">
                <span ><StaticImage  src="../images/resources-arrow.png" alt="Tio Resources" /></span>
            </div>
        
        <div className="container text-xl text-center mx-auto  lg:px-10 ">
            <ul className="flex flex-wrap">
                <li className="w-full lg:w-1/2 p-10 lg:p-20">
                    <a target="_blank" rel="noreferrer" href="https://www.figma.com/file/r6DDpw7TWzUOW22SZrV46S/Social-Media-Template-(Community)?node-id=0%3A1"><StaticImage  src="../images/social-media.jpg" alt="Tio Resources" /></a>
                    <a target="_blank" rel="noreferrer" className="underline py-5 block" href="https://www.figma.com/file/r6DDpw7TWzUOW22SZrV46S/Social-Media-Template-(Community)?node-id=0%3A1">Figma Sosyal Medya Paylaşım Şablonları</a>
                </li>
                <li className="w-full lg:w-1/2 p-10 lg:p-20">
                    <a target="_blank" rel="noreferrer" href="https://www.figma.com/community/file/981209103544766722"><StaticImage  src="../images/empathy.jpg" alt="Tio Resources" /></a>
                    <a target="_blank" rel="noreferrer" className="underline py-5 block" href="https://www.figma.com/community/file/981209103544766722">Empati Haritası</a>
                </li>
                <li className="w-full lg:w-1/2 p-10 lg:p-20">
                    <a target="_blank" rel="noreferrer" href="https://content.tio.ist/urun_on_arastirma.pdf"><StaticImage  src="../images/research.jpg" alt="Tio Resources" /></a>
                    <a target="_blank" rel="noreferrer" className="underline py-5 block" href="https://content.tio.ist/urun_on_arastirma.pdf">Ürün Ön Araştırması</a>
                </li>
                <li className="w-full lg:w-1/2 p-10 lg:p-20">
                    <Link href="/contact"><StaticImage  src="../images/tell-us.jpg" alt="Tio Resources" /></Link>
                   
                </li>
            </ul>
         <p className="text-blue text-2xl p-32 mb-32 lg:text-5xl">
         Bizimle paylaşmak istediğiniz kaynaklar mı var? Bu sayfada yer almak için <Link to="/contact">iletişime geçin</Link>.
         </p>
            
        </div>
        </div>
    </div>
    


  </Layout>
)

export default Resources
